<template>
    <div class="app-container">
        <div class="x-nav">
            <el-breadcrumb :separator="'＞'">
                <el-breadcrumb-item @click="cancel"><a>{{ isMyLess == 1 ? '个人备课' : '协同备课' }}</a></el-breadcrumb-item>
                <el-breadcrumb-item v-if="!lessonId">新增{{ isMyLess == 1 ? '个人备课' : '协同备课' }}</el-breadcrumb-item>
                <el-breadcrumb-item v-if="lessonId">编辑{{ isMyLess == 1 ? '个人备课' : '协同备课' }}</el-breadcrumb-item>

            </el-breadcrumb>
        </div>

        <el-card shadow="never" style="height: calc(100vh - 160px);overflow: auto;">
            <el-form v-if="isSHow" ref="form" :model="form" :rules="rules" label-width="100px">
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="学段" prop="periodKey">
                            <el-select @change="changePeriod" v-model="form.periodKey" placeholder="请选择学段">
                                <el-option v-for="item in periodList" :key="item.dictKey" :label="item.dictValue"
                                    :value="item.dictKey" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="年级" prop="gradeKey">
                            <el-select @change="changeGrade" v-model="form.gradeKey" placeholder="请选择年级">
                                <el-option v-for="item in gradeList" :key="item.dictKey" :label="item.dictValue"
                                    :value="item.dictKey" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="学科" prop="subjectKey">
                            <el-select v-model="form.subjectKey" placeholder="请选择学科">
                                <el-option v-for="item in subjectList" :key="item.dictKey" :label="item.dictValue"
                                    :value="item.dictKey" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="备课标题" prop="title">
                            <el-input v-model="form.title" placeholder="最多32个字" maxlength="32" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-icon @click="toDirectory" class="cursor" :size="24" style="margin-top: 3px;margin-left: 7px;">
                            <Notebook></Notebook>
                        </el-icon>
                    </el-col>
                </el-row>
                <el-row v-if="isMyLess != 1">
                    <el-col :span="12">
                        <el-form-item label="备课性质" prop="lessonNature">
                            <el-select @change="changeLessonNature" style="width: 100%;" v-model="form.lessonNature"
                                placeholder="请选择备课性质">
                                <el-option v-for="item in natureList" :key="item.dictKey" :label="item.dictValue"
                                    :value="item.dictKey" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="课题级别" prop="lessonLevelKey">
                            <el-select style="width: 100%;" v-model="form.lessonLevelKey" placeholder="请选择课题级别">
                                <el-option v-for="item in lessonLevelList" :key="item.dictKey" :label="item.dictValue"
                                    :value="item.dictKey" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="form.lessonNature != '1'">
                    <el-col :span="12">
                        <el-form-item :label="form.lessonNature == 4 ? '群组' : '工作室'" prop="groupId">
                            <el-select @change="changeQun" style="width: 100%;" v-model="form.groupId"
                                placeholder="请选择工作室/群组">
                                <el-option v-for="item in groupList" :key="item.groupId" :label="item.groupName"
                                    :value="item.groupId" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12" v-show="isMyLess == 1">
                        <el-form-item :label="isMyLess == 1 ? '备课人' : '主备人'" prop="leadUser">
                            <el-select :disabled="isMyLess == 1" style="width: 100%;" filterable v-model="form.leadUser"
                                placeholder="请选择主课人">
                                <el-option v-for="item in userList" :key="item.userId" :label="item.trueName"
                                    :value="item.userId" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24" v-show="isMyLess != 1">
                        <el-form-item :label="isMyLess == 1 ? '备课人' : '主备人'" prop="leadUser">
                            <choosePeople :showTitle="'选择人员'" @gxDisable="gxDisable" :radio="true" ref="leadUser" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-show="isMyLess != 1">
                    <el-col :span="24">
                        <el-form-item label="协备人员" prop="teamuseList">
                            <choosePeople :showTitle="'选择人员'" @gxDisable="gxDisable" ref="teamuseList" />

                            <!-- <el-select style="width: 100%;" multiple filterable v-model="form.teamuseList"
                                placeholder="请选择主课人">
                                <el-option v-for="item in userList" :key="item.userId" :label="item.trueName"
                                    :value="item.userId" />
                            </el-select> -->
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-show="isMyLess != 1">
                    <el-col :span="24">
                        <el-form-item label="指导专家" prop="guideUsersList">
                            <choosePeople :showTitle="'选择人员'" @gxDisable="gxDisable" ref="guideUsersList" />
                            <!-- <el-select style="width: 100%;" multiple filterable v-model="form.guideUsersList"
                                placeholder="请选择指导专家">
                                <el-option v-for="item in userList" :key="item.userId" :label="item.trueName"
                                    :value="item.userId" />
                            </el-select> -->
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-show="isMyLess != 1">
                    <el-col :span="12">
                        <el-form-item label="备课时段" prop="formTime">
                            <el-col :span="11" class="date_picker">
                                <el-date-picker format="YYYY-MM-DD HH:mm" @change="startDateConfirm" :disabled-date="disabledDate"
                                    style="width: 100%;" v-model="form.beginTime" type="datetime" placeholder="开始时间"
                                    value-format="x" />
                            </el-col>
                            <el-col :span="2" class="text-center">
                                <span class="text-gray-500">至</span>
                            </el-col>
                            <el-col :span="11">
                                <el-date-picker format="YYYY-MM-DD HH:mm" class="date_picker" @change="endDateConfirm"
                                    :disabled-date="enddisabledFormDate" style="width: 100%;" v-model="form.endTime"
                                    type="datetime" placeholder="结束时间" value-format="x" />
                            </el-col>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-show="isMyLess == 1">
                    <el-col :span="12">
                        <el-form-item label="上传教案" prop="">
                            <el-upload v-model:file-list="jAFileList" class="upload-demo" :on-remove="handleRemove"
                                action="https://www.liyunjy.com/businessservice/lesson/uploadFile"
                                :on-change="handleJAChange" :headers="{ token }"
                                :data="{ fileType: 1, lessonType: 1, lessionId: lessonId }"
                                accept=".pdf,.ppt,.pptx,.doc,.docx" :before-upload="beforeJAUpload">
                                <el-button type="primary">点击上传</el-button>
                                <template #tip>
                                    <div class="el-upload__tip">
                                        只允许上传.pdf,.ppt,.pptx,.doc,.docx格式文件。
                                    </div>
                                </template>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-show="isMyLess == 1">
                    <el-col :span="12">
                        <el-form-item label="上传学案" prop="">
                            <el-upload v-model:file-list="xAFileList" class="upload-demo" :on-remove="handleRemove"
                                action="https://www.liyunjy.com/businessservice/lesson/uploadFile"
                                :on-change="handleXAChange" :headers="{ token }"
                                :data="{ fileType: 4, lessonType: 1, lessionId: lessonId }"
                                accept=".pdf,.ppt,.pptx,.doc,.docx" :before-upload="beforeJAUpload">
                                <el-button type="primary">点击上传</el-button>
                                <template #tip>
                                    <div class="el-upload__tip">
                                        只允许上传.pdf,.ppt,.pptx,.doc,.docx格式文件。
                                    </div>
                                </template>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-show="isMyLess == 1">
                    <el-col :span="12">
                        <el-form-item label="上传课件" prop="">
                            <el-upload v-model:file-list="kJFileList" class="upload-demo" :on-remove="handleRemove"
                                action="https://www.liyunjy.com/businessservice/lesson/uploadFile"
                                :on-change="handleKjChange" :headers="{ token }"
                                :data="{ fileType: 2, lessonType: 1, lessionId: lessonId }"
                                accept=".pdf,.ppt,.pptx,.doc,.docx" :before-upload="beforeJAUpload">
                                <el-button type="primary">点击上传</el-button>
                                <template #tip>
                                    <div class="el-upload__tip">
                                        只允许上传.pdf,.ppt,.pptx,.doc,.docx格式文件。
                                    </div>
                                </template>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-show="isMyLess == 1">
                    <el-col :span="12">
                        <el-form-item label="上传习题" prop="">
                            <el-upload v-model:file-list="xTFileList" class="upload-demo" :on-remove="handleRemove"
                                action="https://www.liyunjy.com/businessservice/lesson/uploadFile"
                                :on-change="handleXTChange" :headers="{ token }"
                                :data="{ fileType: 3, lessonType: 1, lessionId: lessonId }"
                                accept=".pdf,.ppt,.pptx,.doc,.docx" :before-upload="beforeJAUpload">
                                <el-button type="primary">点击上传</el-button>
                                <template #tip>
                                    <div class="el-upload__tip">
                                        只允许上传.pdf,.ppt,.pptx,.doc,.docx格式文件。
                                    </div>
                                </template>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row v-show="isMyLess == 1">
                    <el-col :span="12">
                        <el-form-item label="封面" prop="coverUrl">
                            <el-upload class="avatar-uploader"
                                action="https://www.liyunjy.com/businessservice/lesson/uploadGrCover"
                                :show-file-list="false" :on-success="handleAvatarSuccess" :headers="{ token }"
                                :before-upload="beforeAvatarUpload">
                                <img v-if="form.coverUrl" :src="form.coverUrl" class="avatar" style="position:absolute" />
                                <el-icon v-else class="avatar-uploader-icon" style="font-size:100px;color:#fff;">
                                    <Plus />
                                </el-icon>
                            </el-upload>
                            <div style="color: #a9abad;width:100%;">推荐使用540*304尺寸的图片</div>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>

        </el-card>
        <!-- <div style="width: 390px;height:844px" v-html="form.content">

        </div> -->
        <el-dialog title="课程目录" v-model="isDirectory" width="60%">
            <el-row>
                <el-empty v-if="directoryList.length == 0" style="margin: 0 auto;" description="暂无数据" />
                <div style="margin-bottom: 15px;margin-right:40px;" v-for="item in directoryList">
                    <el-card @click="goCourse(item)" class="cursor" style="width: 150px;">
                        <div class="csbbb">
                            <el-image fit="cover" style="width: 130px;height:185px" :src="item.coverUrl"></el-image>
                        </div>
                        <div class="mt5 text-one">{{ item.className }}</div>
                    </el-card>
                </div>
            </el-row>
        </el-dialog>
        <el-dialog title="课程目录" v-model="isCourse" width="60%">
            <el-table :data="tableData" :span-method="objectSpanMethod" border style="width: 100%; margin-top: 20px">
                <el-table-column prop="ptit" label="单元名" width="180">
                    <template #default="scope">
                        <div style="display: flex;justify-content: space-between;">
                            <div @click="changeTitle(scope.row)" :style="scope.row.title ? '' : 'color: #409efe;'"
                                :class="scope.row.title ? '' : 'cursor'" :title="scope.row.ptit" style="" class="text-one ">
                                {{ scope.row.ptit }}
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="title" label="课程名">
                    <template #default="scope">
                        <div style="display: flex;justify-content: space-between;" v-if="scope.row.id">
                            <div @click="changeTitle(scope.row)" :title="scope.row.title" style="color: #409efe;"
                                class="text-one cursor">
                                {{ scope.row.title }}
                            </div>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </el-dialog>
        <div>
            <div class="disflex-row-center cusbtbtn" :style="$store.state.zdy.isActive ? 'left:26px;' : 'left:226px;'">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="submitForm">保 存</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import { classLibraryListAllBook, classLibraryListTree } from "@/api/admin/classLibrary";
import { lessonCreate, lessonUpdate, delLessonFile } from "@/api/admin/lesson"
import { queryDict, userList,userListJc, listHeadmasterGroup, listTeacherGroup, groupListAuthGroup } from "@/api/admin/public";
import { lessonInfo } from "@/api/admin/lesson"
import choosePeople from "@/components/choosePeople"

export default {
    name: "demoAdd",
    components: {
        choosePeople
    },
    computed: {
    },
    data() {
        return {
            isSHow:false,
            lessonId: '',
            natureList: [],
            jAFileList: [],
            xAFileList: [],
            kJFileList: [],
            xTFileList: [],
            periodList: [],
            gradeList: [],
            subjectList: [],
            lessonLevelList: [],
            userList: [],
            groupList: [],
            token: this.$storage.get("token"),
            // 遮罩层
            loading: true,
            // 非单个禁用
            single: true,
            // 表单参数
            form: {
                coverUrl: 'https://free-back.oss-cn-beijing.aliyuncs.com/ygjy/default/msyk.png',
                groupId: '',
                periodKey: '',
                gradeKey: '',
                subjectKey: '',
                lessonLevelKey: '',
                title: '',
                leadUser: '',
                teamUsers: '',
                teamuseList: [],
                guideUsers: '',
                guideUsersList: [],
                type: 0,
                lessonNature: '1'
            },
            // 表单校验
            rules: {
                lessonNature: [
                    { required: true, message: "请选择备课性质", trigger: "change" },
                ],
                groupId: [
                    { required: true, message: "请选择工作室/群组", trigger: "change" },
                ],
                periodKey: [
                    { required: true, message: "请选择学段", trigger: "change" },
                ],
                gradeKey: [
                    { required: true, message: "请选择年级", trigger: "change" },
                ],
                subjectKey: [
                    { required: true, message: "请选择学科", trigger: "change" },
                ],
                lessonLevelKey: [
                    { required: true, message: "请选择课题级别", trigger: "change" },
                ],
                title: [
                    { required: true, message: "请输入备课标题", trigger: "blur" },
                ],
                leadUser: [
                    { required: true, message: "请选择主备人", trigger: "change" },
                ],
                teamuseList: [
                    { required: true, validator: this.checkteamuseList },
                ],
                formTime: [
                    { required: true, validator: this.checkValidator }
                ],
            },
            delFileList: [],
            isMyLess: 0,
            isDirectory: false,
            directoryList: [],
            tableData: [],
            isCourse: false,
        };
    },
    created() {
        this.getQueryDict();
    },
    mounted() {
        if (this.$route.query.lessonId) {
            this.lessonId = this.$route.query.lessonId
            console.log(this.form)
            this.getlessonInfo()
        } else {
            var userId = JSON.parse(this.$storage.get("userInfo")).userId
            this.form.leadUser = userId
        }
        if (this.$route.query.isMyLess) {
            this.isMyLess = this.$route.query.isMyLess
            console.log(this.isMyLess);
            if (this.isMyLess == 1) {
                this.form.lessonNature = 1
            }
        }
        if (this.isMyLess == 1) {
            this.rules = {
                periodKey: [
                    { required: true, message: "请选择学段", trigger: "change" },
                ],
                gradeKey: [
                    { required: true, message: "请选择年级", trigger: "change" },
                ],
                subjectKey: [
                    { required: true, message: "请选择学科", trigger: "change" },
                ],
                lessonLevelKey: [
                    { required: true, message: "请选择课题级别", trigger: "change" },
                ],
                title: [
                    { required: true, message: "请输入备课标题", trigger: "blur" },
                ],
            }
        }
        this.isSHow = true;
    },
    unmounted() {
        if (this.lessonId) {
            try {
                console.log(332)
                this.jAFileList.forEach(e => {
                    console.log(e)
                    if (e.response.code) {
                        delLessonFile({ fileUrl: e.response.data });
                    }
                })
                this.xAFileList.forEach(e => {
                    if (e.response.code) {
                        delLessonFile({ fileUrl: e.response.data });
                    }
                })
                this.kJFileList.forEach(e => {
                    console.log(e)
                    if (e.response.code) {
                        delLessonFile({ fileUrl: e.response.data });
                    }
                })
                this.xTFileList.forEach(e => {
                    console.log(e)
                    if (e.response.code) {
                        delLessonFile({ fileUrl: e.response.data });
                    }
                })
            } catch (e) {
                console.log('e', e)
            }
        } else {
            try {
                this.jAFileList.forEach(e => {
                    delLessonFile({ fileUrl: e.response.data });
                })
                this.xAFileList.forEach(e => {
                    delLessonFile({ fileUrl: e.response.data });
                })
                this.kJFileList.forEach(e => {
                    delLessonFile({ fileUrl: e.response.data });
                })
                this.xTFileList.forEach(e => {
                    delLessonFile({ fileUrl: e.response.data });
                })
            } catch (e) {
                console.log('e', e)
            }

        }
    },
    computed: {
    },
    methods: {
        changeQun(e) {
            console.log(this.form.groupId);
            this.sxKj()
            this.$refs.leadUser.getQunPeople(this.form.groupId)

            this.$refs.teamuseList.getQunPeople(this.form.groupId)
            this.$refs.guideUsersList.getQunPeople(this.form.groupId)
        },
        gxDisable() {
            this.$refs.leadUser.disabledList = [...this.$refs.teamuseList.checkList, ...this.$refs.guideUsersList.checkList]
            this.$refs.teamuseList.disabledList = [...this.$refs.leadUser.checkList, ...this.$refs.guideUsersList.checkList]
            this.$refs.guideUsersList.disabledList = [...this.$refs.leadUser.checkList, ...this.$refs.teamuseList.checkList]
        },
        changeTitle(row) {
            console.log(row);
            this.form.title = row.className + '­—' + row.ptit + (row.title ? '­—' + row.title : '')
            this.isDirectory = false;
            this.isCourse = false;
        },
        goCourse(item) {
            this.tableData = []
            classLibraryListTree().then(res => {
                res.data.data.forEach(e => {
                    if (item.classId == e.classId) {
                        if (e.childrenList) {
                            e.childrenList.forEach(j => {
                                if (j.childrenList) {
                                    j.childrenList.forEach((m, n) => {
                                        if (n == 0) {
                                            this.tableData.push({ pid: j.classId, ptit: j.className, id: m.classId, title: m.className, col: j.childrenList.length, className: e.className })
                                        } else {
                                            this.tableData.push({ pid: j.classId, ptit: j.className, id: m.classId, title: m.className, col: 0, className: e.className })
                                        }
                                    })
                                } else {
                                    this.tableData.push({ pid: j.classId, ptit: j.className, id: '', title: '', col: 1, className: e.className })
                                }
                            })
                        }
                    }
                })
                console.log(this.tableData);
                this.isCourse = true
            })
        },
        objectSpanMethod(e) {
            // console.log(e);
            if (e.columnIndex === 0) {
                if (e.row.col != 0) {
                    return {
                        rowspan: e.row.col,
                        colspan: 1,
                    }
                } else {
                    return {
                        rowspan: 0,
                        colspan: 0,
                    }
                }
            }
        },
        toDirectory() {
            classLibraryListAllBook().then(res => {
                if (this.form.periodKey !== '') {
                    res.data.data = res.data.data.filter(itemA => {
                        return itemA.periodKey == this.form.periodKey
                    })
                }
                if (this.form.gradeKey !== '') {
                    res.data.data = res.data.data.filter(itemA => {
                        return itemA.gradeKey == this.form.gradeKey
                    })
                }
                if (this.form.subjectKey !== '') {
                    res.data.data = res.data.data.filter(itemA => {
                        return itemA.subjectKey == this.form.subjectKey
                    })
                }
                this.directoryList = JSON.parse(JSON.stringify(res.data.data));
                this.isDirectory = true;
            });
        },
        changeLessonNature(e) {
            console.log(e);
            this.groupList = [];
            // if (!this.lessonId) {
            this.form.groupId = '';
            // }

            var userId = JSON.parse(this.$storage.get("userInfo")).userId
            if (e == 1) {
                this.sxKj()
                this.$refs.leadUser.getUserList()
                this.$refs.teamuseList.getUserList()
                this.$refs.guideUsersList.getUserList()
            } else if (e == 2) {
                // listTeacherGroup().then(res => {
                //     res.data.data.forEach(j => {
                //         j.memberList.forEach(m => {
                //             if (m.memberRole == 0 || m.memberRole == 1) {
                //                 if (m.memberUserId == userId) {
                //                     this.groupList.push(j)
                //                     // throw new Error();
                //                 }
                //             }
                //         })
                //     })
                // })
                groupListAuthGroup({ groupNature: 2 }).then(res => {
                    this.groupList = res.data.data;
                })
                this.sxKj()
                this.$refs.leadUser.getUserList()
                this.$refs.teamuseList.getUserList()
                this.$refs.guideUsersList.getUserList()
            } else if (e == 3) {
                // listHeadmasterGroup().then(res => {
                //     res.data.data.forEach(j => {
                //         j.memberList.forEach(m => {
                //             if (m.memberRole == 0 || m.memberRole == 1) {
                //                 if (m.memberUserId == userId) {
                //                     this.groupList.push(j)
                //                     // throw new Error();
                //                 }
                //             }
                //         })
                //     })
                // })
                groupListAuthGroup({ groupNature: 3 }).then(res => {
                    this.groupList = res.data.data;
                })
                this.sxKj()
                this.$refs.leadUser.getUserList()
                this.$refs.teamuseList.getUserList()
                this.$refs.guideUsersList.getUserList()
            } else if (e == 4) {
                this.sxKj()
                groupListAuthGroup({ groupNature: 1 }).then(res => {
                    this.groupList = res.data.data;
                })
            }
        },
        sxKj() {
            this.$refs.leadUser.checkList = []
            this.$refs.leadUser.disabledList = []
            // this.$refs.leadUser.checkList = []
            this.$refs.teamuseList.checkList = []
            this.$refs.guideUsersList.checkList = []
            // this.$refs.leadUser.disabledList = []
            this.$refs.teamuseList.disabledList = []
            this.$refs.guideUsersList.disabledList = []
        },
        handleRemove(file, uploadFiles) {
            console.log(file, uploadFiles)
            if (file.response.data) {
                this.delFileList.push(file.response.data)
            }
        },
        getlessonInfo() {
            queryDict({ code: 'lession_xd_nj_xk' }).then(res => {
                this.periodList = res.data.data;
                lessonInfo({ lessonId: this.lessonId }).then(res => {
                    this.changeLessonNature(res.data.data.lessonNature)
                    var textList = [];
                    res.data.data.teamUserList.forEach(j => {
                        textList.push(j.trueName)
                    })
                    console.log(textList)
                    res.data.data.teamUserText = textList.join('、')
                    this.form = res.data.data;
                    if (this.isMyLess != 1) {
                        this.$refs.leadUser.checkList = [{ userId: this.form.leadUser, trueName: this.form.leadUserName }];
                        this.$refs.teamuseList.checkList = this.form.teamUserList;
                        this.$refs.guideUsersList.checkList = this.form.guideUserList;
                        this.$refs.leadUser.disabledList = [...this.$refs.teamuseList.checkList, ...this.$refs.guideUsersList.checkList]
                        this.$refs.teamuseList.disabledList = [...this.$refs.leadUser.checkList, ...this.$refs.guideUsersList.checkList]
                        this.$refs.guideUsersList.disabledList = [...this.$refs.leadUser.checkList, ...this.$refs.teamuseList.checkList]
                        if (this.form.lessonNature == 2 || this.form.lessonNature == 3 || this.form.lessonNature == 4) {
                            this.$refs.leadUser.getQunPeople(this.form.groupId)
                            this.$refs.teamuseList.getQunPeople(this.form.groupId)
                            this.$refs.guideUsersList.getQunPeople(this.form.groupId)
                        }
                    }


                    // this.form.teamuseList = this.form.teamUsers.split(',')
                    // this.form.guideUsersList = this.form.guideUsers.split(',')
                    this.form.gajaFiles.forEach(e => {
                        this.jAFileList.push({
                            name: e.fileName,
                            fileId: e.fileId,
                            url: e.fileUrl,
                            response: {
                                data: e.fileUrl
                            }
                        })
                    })
                    this.form.gaxaFiles.forEach(e => {
                        this.xAFileList.push({
                            name: e.fileName,
                            fileId: e.fileId,
                            url: e.fileUrl,
                            response: {
                                data: e.fileUrl
                            }
                        })
                    })
                    this.form.gakjFiles.forEach(e => {
                        this.kJFileList.push({
                            name: e.fileName,
                            fileId: e.fileId,
                            url: e.fileUrl,
                            response: {
                                data: e.fileUrl
                            }
                        })
                    })
                    this.form.gaxtFiles.forEach(e => {
                        this.xTFileList.push({
                            name: e.fileName,
                            fileId: e.fileId,
                            url: e.fileUrl,
                            response: {
                                data: e.fileUrl
                            }
                        })
                    })
                    if (this.isMyLess == 1) {
                        this.form.lessonNature = 1
                    }
                    console.log(this.periodList)
                    this.periodList.forEach(j => {
                        if (this.form.periodKey == j.dictKey) {
                            this.gradeList = j.childrenList;
                        }
                    })
                    this.periodList.forEach(e => {
                        if (this.form.periodKey == e.dictKey) {
                            e.childrenList.forEach(j => {
                                if (this.form.gradeKey == j.dictKey) {
                                    this.subjectList = j.childrenList;
                                }
                            })
                        }
                    })
                })
            })

        },
        handleXTChange(file) {
            console.log(file)
        },
        handleJAChange(file) {
            console.log(file)
        },
        handleXAChange(file) {
            console.log(file);
        },
        handleKjChange(file) {
            console.log(file)

        },
        beforeJAUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type == 'application/pdf' || rawFile.type == 'application/vnd.ms-powerpoint' || rawFile.type == 'application/vnd.openxmlformats-officedocument.presentationml.presentation' || rawFile.type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || rawFile.type == 'application/msword')) {
                this.$message.error('文件必须是.pdf,.ppt,.pptx,.doc,.docx格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 200) {
                this.$message.error('文件大小不能超过200M')
                return false
            }
            return true
        },
        startDateConfirm(e) {
            if (e >= this.form.endTime) {
                this.form.endTime = '';
            }
        },
        endDateConfirm(e) {
            console.log(e,)
            if (e <= this.form.beginTime) {
                this.form.beginTime = '';
            }
        },
        checkValidator(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            if (this.form.beginTime === '' || !this.form.beginTime) {
                callback(new Error('请选择开始日期'))
            } else if (this.form.endTime === '' || !this.form.endTime) {
                callback(new Error('请选择结束日期'))
            } else {
                callback()
            }
        },
        checkteamuseList(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            if (this.$refs.teamuseList.checkList.length == 0) {
                callback(new Error('请选择协备人员'))
            } else {
                callback()
            }
        },
        disabledDate(current) {
            return current && current < Number(new Date()) - 86400000
        },
        enddisabledDate(current) {
            if (this.form.beginTime) {
                return current && current < this.form.beginTime
            } else {
                return current && current < Number(new Date()) - 86400000
            }
        },
        changePeriod() {
            this.form.gradeKey = '';
            this.form.subjectKey = '';
            this.gradeList = [];
            this.subjectList = [];
            this.periodList.forEach(j => {
                if (this.form.periodKey == j.dictKey) {
                    this.gradeList = j.childrenList;
                }
            })
        },
        changeGrade() {
            this.form.subjectKey = '';
            this.subjectList = []
            this.periodList.forEach(e => {
                if (this.form.periodKey == e.dictKey) {
                    e.childrenList.forEach(j => {
                        if (this.form.gradeKey == j.dictKey) {
                            this.subjectList = j.childrenList;
                        }
                    })
                }
            })
        },
        getQueryDict() {
            queryDict({ code: 'lession_xd_nj_xk' }).then(res => {
                this.periodList = res.data.data;
            })
            queryDict({ code: 'topic_level' }).then(res => {
                this.lessonLevelList = res.data.data;
            })
            queryDict({ code: 'activity_nature' }).then(res => {
                this.natureList = res.data.data;
            })
            userListJc().then(res => {
                this.userList = res.data.data;
            })
        },
        handleAvatarSuccess(response, uploadFile) {
            console.log(response, uploadFile);
            if (uploadFile.response.code == 1) {
                this.form.coverUrl = uploadFile.response.data;
            } else {
                this.$message.error("上传失败")
            }
        },
        beforeAvatarUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type == 'image/jpeg' || rawFile.type == 'image/png' || rawFile.type == 'image/jpg')) {
                this.$message.error('图片必须是jpeg、png、jpg格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 10) {
                this.$message.error('图片大小不能超过10M')
                return false
            }
            return true
        },
        /** 提交按钮 */
        submitForm() {
            this.form.beginTime = this.setHms(this.form.beginTime);
            this.form.endTime = this.setHms(this.form.endTime);
            this.form.teamuseList = [];
            this.form.guideUsersList = [];
            if (this.isMyLess != 1) {
                this.$refs.teamuseList.checkList.forEach(e => {
                    this.form.teamuseList.push(e.userId)
                })
                this.$refs.guideUsersList.checkList.forEach(e => {
                    this.form.guideUsersList.push(e.userId)
                })
                if (this.$refs.leadUser.checkList.length != 0) {
                    this.form.leadUser = this.$refs.leadUser.checkList[0].userId;
                } else {
                    this.form.leadUser = '';
                }
            }


            console.log(this.form)
            console.log(this.jAFileList)
            console.log(this.kJFileList)
            console.log(this.xTFileList)
            var fileList = [];
            this.form.fileUrls = ''
            this.jAFileList.forEach((e) => {
                if (e.response.code == 1) {
                    fileList.push(e.response.data);
                }
            })
            this.xAFileList.forEach((e) => {
                if (e.response.code == 1) {
                    fileList.push(e.response.data);
                }
            })
            this.kJFileList.forEach((e) => {
                if (e.response.code == 1) {
                    fileList.push(e.response.data);
                }
            })
            this.xTFileList.forEach((e) => {
                if (e.response.code == 1) {
                    fileList.push(e.response.data);
                }
            })

            this.form.teamUsers = this.form.teamuseList.toString()
            this.form.guideUsers = this.form.guideUsersList.toString()
            this.$refs["form"].validate(valid => {
                if (valid) {
                    console.log(fileList)
                    var obj = JSON.parse(JSON.stringify(this.form));
                    obj.fileUrls = fileList.toString()
                    console.log(obj)
                    if (this.isMyLess == 1) {
                        obj.type = 1
                    } else {
                        this.form.coverUrl = ''
                        obj.type = 0
                    }
                    delete obj.teamuseList
                    delete obj.guideUsersList
                    if (this.lessonId) {
                        delete obj.jaFiles
                        delete obj.kjFiles
                        delete obj.xtFiles
                        lessonUpdate(obj).then(res => {
                            if (res.data.code == 1) {
                                this.jAFileList = []
                                this.xAFileList = []
                                this.kJFileList = []
                                this.xTFileList = []
                                this.delFileList.forEach(e => {
                                    delLessonFile({ fileUrl: e });
                                })
                                this.$message.success("编辑成功");
                                if (this.isMyLess == 1) {
                                    this.replace('/myLess', { isSx: 1 })
                                } else {
                                    this.replace('/lesson', { isSx: 1 })
                                }
                            } else {
                                if (this.isMyLess == 1) {
                                    this.replace('/myLess', { isSx: 1 })
                                } else {
                                    this.$message.error("操作失败")
                                }
                            }
                        })
                    } else {
                        lessonCreate(obj).then(res => {
                            if (res.data.code == 1) {
                                this.jAFileList = []
                                this.xAFileList = []
                                this.kJFileList = []
                                this.xTFileList = []
                                this.delFileList.forEach(e => {
                                    delLessonFile({ fileUrl: e });
                                })
                                this.$message.success("新增成功");
                                if (this.isMyLess == 1) {
                                    this.replace('/myLess', { isSx: 1 })
                                } else {
                                    this.replace('/lesson', { isSx: 1 })
                                }

                                // this.$emit('isTg', true);
                            } else {
                                this.$message.error("操作失败")
                            }

                        });
                    }

                }
            });
        },

        // 取消按钮
        cancel() {
            if (this.isMyLess == 1) {
                this.replace('/myLess')
            } else {
                this.replace('/lesson')
            }
            // this.jAFileList = [];

            // this.$emit('isTg', true)
        },
    }
};
</script>
<style scoped>
.avatar-uploader .avatar {
    width: 270px;
    height: 152px;
    display: block;
    object-fit: cover;
}

.avatar-uploader /deep/ .el-upload {
    width: 270px;
    height: 152px;
    border: 1px dashed var(--el-border-color);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
    border-color: var(--el-color-primary);
}

.el-icon .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 270px;
    height: 152px;
    text-align: center;
}


/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

/deep/ .el-dialog__body {
    padding: 10px 20px !important;
}

.custom /deep/ .el-checkbox {
    height: auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.custom /deep/ .el-checkbox-group {
    display: flex;
    flex-wrap: wrap;
}

.custom /deep/ .el-checkbox__label {
    padding-left: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

.custom {
    height: calc(100vh - 500px);
    overflow-y: auto;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    position: relative;
}

.ylsb {
    height: 50vh;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
}

.ylqs {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 7px;
    padding: 5px;
    cursor: pointer;
}

.eicon {
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px;
    background: #dbdbdb;
}

.csbbb:hover .eicon {
    display: block;
}
</style>