<template>
    <div>
        <TbHeader></TbHeader>
        <el-row style="background: #f2f2f2;">
            <el-col :span="3"></el-col>
            <el-col :span="18" style="background-color: #fff;">
                <div ref="containerRef" style="height:calc(100vh - 60px);overflow-y:auto">
                    <div style="position: fixed;width: 74%;z-index: 999;">
                        <el-row class="" style="padding: 15px 0;background: rgba(255, 255, 255,0.5);">
                            <el-col :span="10" class="mb0">
                                <el-form-item label="题目检索" class="ml15" label-width="80px">
                                    <el-input v-model="queryParams.title" placeholder="请输入要搜索的题目关键字"
                                        class="input-with-select">
                                        <template #append>
                                            <el-button @click="handleQuery" type="primary" icon="el-icon-search" />
                                        </template>
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="14" class="mr0 mb0">
                                <el-form-item class="pull-right">
                                    <el-button class="mr15" type="warning">提交</el-button>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </div>
                    <div class="pd15" style="height: 1000px;" id="part1">
                        <div class="bghTit" style="margin-top: 70px;">
                            <div class="bghTitTxt">第一部分:教师基础信息</div>
                        </div>
                        <el-form class="mt40" :label-position="'left'" label-width="120px" style="max-width: 50%">
                            <el-form-item label="性别">
                                <el-select v-model="grXX.gender" placeholder="请选择">
                                    <el-option :key="0" :label="'男'" :value="0" />
                                    <el-option :key="1" :label="'女'" :value="1" />
                                </el-select>
                            </el-form-item>
                            <el-form-item label="出生日期">
                                <el-date-picker v-model="grXX.birthDate" type="date" value-format="x" placeholder="请选择日期"
                                    style="width: 100%" />
                            </el-form-item>
                            <el-form-item label="政治面貌">
                                <el-select v-model="grXX.gender" placeholder="请选择">
                                    <el-option :key="0" :label="'男'" :value="0" />
                                    <el-option :key="1" :label="'女'" :value="1" />
                                </el-select>
                            </el-form-item>
                            <el-form-item label="民族">
                                <el-select v-model="grXX.gender" placeholder="请选择">
                                    <el-option :key="0" :label="'男'" :value="0" />
                                    <el-option :key="1" :label="'女'" :value="1" />
                                </el-select>
                            </el-form-item>

                        </el-form>
                        <div class="tip custom-block">
                            <div class="ml5" style="font-size: 17px;">
                                学历和工作信息
                            </div>
                        </div>
                        <div class="ml30">
                            全日制学校毕业学历(备注:只填写入职前全日制学校毕业的最高学历)
                        </div>
                        <div class="cdx tip custom-block ml70 " style="width: 50%;">
                            <div style="flex:1" class="ml15 mr10 cursor">
                                <p class="custom-block-title">TIP</p>
                                <div class="text-one">默认值是 false，这时候只能通过checkbox 来选中，当然您也可以将其设置成 true，这样点击整个 node 都可以用来完成选择
                                </div>
                            </div>
                            <div class="cdxtpbx">
                                <img class="cdxRgIcon mr10 cursor" src="../../assets/img/biaoq.png" alt="">
                                <div class="cdxDel">
                                    <el-icon class="cursor ml5">
                                        <Delete />
                                    </el-icon><span class="ml5 cursor">点击删除资料</span>
                                </div>
                            </div>
                        </div>
                        <div class="ml70 " style="width: 50%;">
                            <div class="pl10 mr10 cursor cdxAdd" @click="handleAddFile">
                                + 点击添加档案资料，如有多项符合条件，请逐项添加
                            </div>
                        </div>
                    </div>
                    <div class="pd15" style="height: 1000px;" id="part2">
                        <div class="bghTit" style="margin-top: 70px;">
                            <div class="bghTitTxt">第二部分:教师基础信息</div>
                        </div>
                    </div>
                    <div class="pd15" style="height: 1000px;" id="part3">
                        <div class="bghTit" style="margin-top: 70px;">
                            <div class="bghTitTxt">第三部分:教师基础信息</div>
                        </div>
                    </div>
                </div>
            </el-col>
            <el-col :span="3">
                <div style="top: 50%;position: absolute;width: 12.5%;transform: translateY(-50%);">
                    <el-anchor style="background: transparent;font-size:20px" :container="containerRef" type="underline"
                        direction="vertical" :offset="30" @click="handleClick">
                        <el-anchor-link :href="`#part1`" title="part1">
                        </el-anchor-link>
                        <el-anchor-link :href="`#part2`" title="part2">
                        </el-anchor-link>
                        <el-anchor-link :href="`#part3`" title="part3">
                        </el-anchor-link>
                    </el-anchor>
                </div>
            </el-col>
        </el-row>
        <el-dialog draggable :title="schoolModelObj.tit" v-model="schoolModelObj.isShow" width="700px">
            <el-form :label-position="'left'" ref="schoolModelObj" :model="schoolModelObj" :rules="schoolModelRules"
                label-width="150px">
                <el-form-item label="全日制学校毕业学历" prop="qualification">
                    <el-select filterable v-model="schoolModelObj.qualification" placeholder="请选择">
                        <el-option :key="0" :label="'男'" :value="0" />
                        <el-option :key="1" :label="'女'" :value="1" />
                    </el-select>
                </el-form-item>
                <el-form-item label="起止时间" prop="qualification">
                    <el-date-picker v-model="schoolModelObj.timeList" type="monthrange" range-separator="到"
                        start-placeholder="开始时间" end-placeholder="结束时间" />
                </el-form-item>
                <el-form-item label="毕业院校" prop="orgName">
                    <el-input v-model="schoolModelObj.orgName" placeholder="最多32个字" maxlength="32" />
                </el-form-item>
                <el-form-item label="毕业专业" prop="major">
                    <el-input v-model="schoolModelObj.major" placeholder="最多32个字" maxlength="32" />
                </el-form-item>
                <el-form-item label="是否师范类专业" prop="qualification">
                    <el-select filterable v-model="schoolModelObj.qualification" placeholder="请选择">
                        <el-option :key="0" :label="'是'" :value="0" />
                        <el-option :key="1" :label="'否'" :value="1" />
                    </el-select>
                </el-form-item>
                <el-form-item label="毕业证书" prop="annex1UrlList">
                    <div style="display: flex;flex-wrap:wrap;">
                        <div v-for="(item, index) in schoolModelObj.annex1UrlList" class="csbbb">
                            <el-icon @click="delImg(index)" :size="20" class="eicon"
                                style="cursor: pointer;z-index: 99;color: red;">
                                <Delete />
                            </el-icon>
                            <img :src="item.response.data" v-if="item.response"
                                style="width: 100px;height: 65px;margin-right: 5px;margin-bottom: 5px;">
                        </div>
                    </div>
                    <el-upload v-model:file-list="schoolModelObj.annex1UrlList" :headers="{ token }" multiple :show-file-list="false"
                        action="https://www.liyunjy.com/businessservice/courseware/recommendImg" class="avatar-uploader"
                        :before-upload="beforeAvatarUpload" accept=".jpeg,.png,.jpg" list-type="picture-card"
                        :on-remove="handleRemove">
                        <el-icon class="avatar-uploader-icon" style="font-size:50px;">
                            <Plus />
                        </el-icon>
                    </el-upload>
                </el-form-item>
            </el-form>
            <template #footer>
                <div class="disflex-row-center">
                    <el-button type="primary">确 定</el-button>
                    <el-button @click="schoolModelObj.isShow = false">取 消</el-button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>
<script>
export default {
    components: {
    },
    name: "fileFill",
    data() {
        return {
            userInfo: {},
            queryParams: {
                title: ''
            },
            grXX: {
                gender: '',
                birthDate: '',
                politicalClimate: '',
                nation: '',
            },
            containerRef: null,
            schoolModelObj: {
                tit: '全日制学校毕业学历',
                isShow: false,
                annex1UrlList:[],
            },
            schoolModelRules: [],
            delFileList:[],
            token: this.$storage.get("token"),
        }
    },
    computed: {
    },
    watch: {
        $route(to, from) {
            console.log('to', to)
        },
    },
    created() {
    },
    mounted() {
        this.containerRef = this.$refs.containerRef;
    },
    methods: {
        handleRemove(file, uploadFiles) {
            console.log(file, uploadFiles, this.recommendImgList)
            if (file.response) {
                this.delFileList.push(file.response.data)
            }
        },
        beforeAvatarUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type == 'image/jpeg' || rawFile.type == 'image/png' || rawFile.type == 'image/jpg')) {
                this.$message.error('图片必须是jpeg、png、jpg格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 10) {
                this.$message.error('图片大小不能超过10M')
                return false
            }
            return true
        },
        handleAddFile() {
            this.schoolModelObj.isShow = true;
        },
        handleClick(e) {
            console.log(e, this.containerRef, this.$refs.containerRef);
            console.log(this.$refs.containerRef);
            e.preventDefault()
        },
        delImg(index) {
            this.schoolModelObj.annex1UrlList.splice(index, 1)
        },
    }
}
</script>
  <!--内联样式-->
<style lang="scss" scoped>
/deep/ .el-anchor__link {
    font-size: 16px !important;
}

/deep/ .el-form-item__label {
    color: #333 !important;
    // font-weight: bold;
    line-height: 32px;
}
.eicon {
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px;
    background: #dbdbdb;
}

.csbbb:hover .eicon {
    display: block;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    position: relative;
}

.csbbb .eicon {
    display: none;
    position: absolute;
    right: 20px;
    top: 5px;
    background-color: #e4e7ed;
}

.csbbb:hover .eicon {
    display: block;
}
.avatar-uploader .avatar {
    width: 100px;
    height: 100px;
    display: block;
    object-fit: cover;
}
.avatar-uploader{
    padding: 0 15px;
}
.avatar-uploader /deep/ .el-upload {
    border: 1px dashed var(--el-border-color);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
    width: 100px;
    height: 100px;
    display: block;
    object-fit: cover;
}

.avatar-uploader .el-upload:hover {
    border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    text-align: center;
}
</style>
  