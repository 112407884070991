import VueAMap, { initAMapApiLoader } from '@vuemap/vue-amap'
import '@vuemap/vue-amap/dist/style.css'

window._AMapSecurityConfig = {
    securityJsCode: 'e94bf24d91803bdad1ac63b71e90508d',
}
initAMapApiLoader({
    key: '6267c4fe32911749602772b0ecb25d67',  //备用测试key e1dedc6bdd765d46693986ff7ff969f4
    version: "2.0",   // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
    // 需要使用的的插件列表，如比例尺'AMap.Scale'等
    plugin: [
        'AMap.Autocomplete', // 输入提示插件
        'AMap.PlaceSearch', // POI搜索插件
        'AMap.Scale', // 右下角缩略图插件 比例尺
        'AMap.OverView', // 地图鹰眼插件
        'AMap.ToolBar', // 地图工具条
        'AMap.Geolocation', // 定位控件，用来获取和展示用户主机所在的经纬度位置
        'AMap.Geocoder',
        'AMap.MapType',
        "AMap.AMapUI",// UI组件
        "AMap.Driving",
        "AMap.CitySearch"
    ],
    AMapUI: {             // 是否加载 AMapUI，缺省不加载
        "version": '1.1',   // AMapUI 版本
        "plugins": ['overlay/SimpleMarker'],       // 需要加载的 AMapUI ui插件
    },
})
// 捕获未知错误 并重载页面
window.addEventListener('error', (e) => {
    console.log(e, 'error')
}, true)
export default VueAMap