<template>
    <div class="app-container">
        <div class="x-nav">
            <el-breadcrumb :separator="'＞'">
                <el-breadcrumb-item @click="cancel"><a>工作室管理</a></el-breadcrumb-item>
                <el-breadcrumb-item v-if="!$route.query.infoId">新增{{ form.type ==
                    1 ? '工作简报' : form.type == 2 ? '教师文章' : form.type == 3 ? '成果展示' : form.type == 4 ? '工作日志' : ''
                }}</el-breadcrumb-item>
                <el-breadcrumb-item v-if="$route.query.infoId">编辑{{ form.type ==
                    1 ? '工作简报' : form.type == 2 ? '教师文章' : form.type == 3 ? '成果展示' : form.type == 4 ? '工作日志' : ''
                }}</el-breadcrumb-item>

            </el-breadcrumb>
        </div>

        <el-card shadow="never" style="height: calc(100vh - 160px);overflow: auto;">
            <el-form ref="form" :model="form" :rules="rules" label-width="100px">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="标题" prop="title">
                            <el-input v-model="form.title" placeholder="最多32个字" maxlength="32" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="分类" prop="classify">
                            <el-select style="width: 100%;" v-model="form.classify" placeholder="请选择分类">
                                <el-option v-for="item in studioClassifyList" :key="item.dictKey" :label="item.dictValue"
                                    :value="item.dictKey">
                                    <span style="float: left">{{ item.dictValue }}</span>
                                    <span @click.stop="delFx(item)" style="float: right; color: #8492a6; font-size: 13px">
                                        <el-icon>
                                            <Delete />
                                        </el-icon>
                                    </span>
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="4">
                        <el-icon @click="openStudioClassify" class="ml15 cursor" :size="30">
                            <CirclePlus />
                        </el-icon>
                    </el-col>
                </el-row>
                <el-row v-if="form.type == 1 || form.type == 3">
                    <el-col :span="12">
                        <el-form-item label="封面" prop="cover">
                            <el-upload class="avatar-uploader" action="https://www.liyunjy.com/businessservice/news/upload"
                                :show-file-list="false" :on-success="handleAvatarSuccess" :headers="{ token }"
                                :before-upload="beforeAvatarUpload">
                                <img v-if="form.cover" :src="form.cover" class="avatar" style="position:absolute" />
                                <el-icon class="avatar-uploader-icon" style="font-size:100px;color:#fff;">
                                    <Plus />
                                </el-icon>
                            </el-upload>
                            <div style="color: #a9abad;width:100%;">推荐使用540*304尺寸的图片</div>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="内容" prop="content">
                            <!-- <el-input type="textarea" v-model="form.content" /> -->
                            <TEditor :height="'40vh'" ref="editor" :content="form.content" v-model="form.content" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="form.type == 3">
                    <el-col :span="12">
                        <el-form-item label="上传附件" prop="">
                            <el-upload v-model:file-list="fileList" class="upload-demo" :on-remove="handleRemove"
                                action="https://www.liyunjy.com/businessservice/studio/achieveFile"
                                :on-change="handleJAChange" :headers="{ token }" accept=".pdf,.ppt,.pptx,.doc,.docx"
                                :before-upload="beforeJAUpload">
                                <el-button type="primary">点击上传</el-button>
                                <template #tip>
                                    <div class="el-upload__tip">
                                        只允许上传.pdf,.ppt,.pptx,.doc,.docx格式文件。
                                    </div>
                                </template>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <!-- <div style="width: 390px;height:844px" v-html="form.content">

        </div> -->
        <div>
            <div class="disflex-row-center cusbtbtn" :style="$store.state.zdy.isActive ? 'left:26px;' : 'left:226px;'">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="submitForm">保 存</el-button>
            </div>
        </div>
        <el-dialog :title="'创建资讯分类'" v-model="studioClassifyShow" width="60%">
            <el-row>
                <el-col :span="12">
                    <el-form-item label="名称" prop="classifyName">
                        <el-input v-model="classifyName" placeholder="最多32个字" maxlength="32" />
                    </el-form-item>
                </el-col>
            </el-row>
            <template #footer>
                <div class="disflex-row-center">
                    <el-button type="primary" @click="submitStudioClassifyForm">确 定</el-button>
                    <el-button @click="studioClassifyShow = false">取 消</el-button>
                </div>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import { ElMessage, ElMessageBox } from 'element-plus'
import { newsCreate, newsUpdate } from "@/api/admin/news"
import TEditor from '@/components/TEditor.vue';
import { queryDict, deleteDict } from "@/api/admin/public";
import { studioDeleteFile, studioCreate, studioUpdate, studioInfo, studioAddClassify } from "@/api/admin/studio";

export default {
    name: "demoAdd",
    components: {
        TEditor
    },
    data() {
        return {
            fileList: [],
            delFileList: [],
            classifyName: '',
            studioClassifyList: [],
            studioClassifyShow: false,
            token: this.$storage.get("token"),
            // 遮罩层
            loading: true,
            // 非单个禁用
            single: true,
            // 表单参数
            form: {
                content: '',
                appContent: '',
                cover: 'https://free-back.oss-cn-beijing.aliyuncs.com/ygjy/default/news.png',
                title: '',
                type: '',
                groupId: ''
            },
            natureList: [],
            // 表单校验
            rules: {
                title: [
                    { required: true, message: "请输入新闻封面", trigger: "blur" },
                ],
                cover: [
                    { required: true, validator: this.checkCoverValidator }
                ],
                content: [
                    { required: true, validator: this.checkContentValidator },
                ],
            },
            infoId: '',
            isTeachStudioList: 0,
            iswork: 0,
        };
    },
    created() {
    },
    mounted() {
        if (this.$route.query.iswork) {
            this.iswork = this.$route.query.iswork;
        }
        if (this.$route.query.isTeachStudioList) {
            this.isTeachStudioList = this.$route.query.isTeachStudioList;
        }
        if (this.$route.query.infoId) {
            this.infoId = this.$route.query.infoId
            this.getStudioInfo()
        }
        if (this.$route.query.groupId) {
            this.form.groupId = this.$route.query.groupId
            console.log(this.form)
        }
        if (this.$route.query.type) {
            this.form.type = this.$route.query.type
        }
        this.getQueryDict();

    },
    unmounted() {
        if (this.infoId) {
            try {
                console.log(332)
                this.fileList.forEach(e => {
                    console.log(e)
                    if (e.response.code) {
                        studioDeleteFile({ fileUrl: e.response.data });
                    }
                })
            } catch (e) {
                console.log('e', e)
            }
        } else {
            try {
                this.fileList.forEach(e => {
                    studioDeleteFile({ fileUrl: e.response.data });
                })
            } catch (e) {
                console.log('e', e)
            }

        }

    },
    computed: {
    },
    methods: {
        handleJAChange(file) {
            console.log(file)
        },
        beforeJAUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type == 'application/pdf' || rawFile.type == 'application/vnd.ms-powerpoint' || rawFile.type == 'application/vnd.openxmlformats-officedocument.presentationml.presentation' || rawFile.type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || rawFile.type == 'application/msword')) {
                this.$message.error('文件必须是.pdf,.ppt,.pptx,.doc,.docx格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 200) {
                this.$message.error('文件大小不能超过200M')
                return false
            }
            return true
        },
        handleRemove(file, uploadFiles) {
            console.log(file, uploadFiles)
            if (file.response.data) {
                this.delFileList.push(file.response.data)
            }
        },
        delFx(item) {
            var _this = this;
            ElMessageBox.confirm(
                '是否删除该课分类?',
                '删除',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            ).then(() => {
                deleteDict({ dicId: item.dicId }).then(res => {
                    if (res.data.code == 1) {
                        if (_this.form.classify == item.dictKey) {
                            _this.form.classify = ''
                        }
                        _this.$message.success('删除成功')
                        queryDict({ code: 'studio_info_type_' + _this.form.type }).then(res => {
                            this.studioClassifyList = res.data.data;
                        })
                    } else {
                        _this.$message.error('操作失败')
                    }
                })
            }).catch(() => {

            })
        },
        openStudioClassify() {
            this.classifyName = ''
            this.studioClassifyShow = true;
        },
        getQueryDict() {
            queryDict({ code: 'studio_info_type_' + this.form.type }).then(res => {
                this.studioClassifyList = res.data.data;
            })
        },
        submitStudioClassifyForm() {
            studioAddClassify({ classifyName: this.classifyName, type: this.form.type }).then(res => {
                if (res.data.code == 1) {
                    this.getQueryDict();
                    this.$message.success('添加成功')
                    this.studioClassifyShow = false;
                } else {
                    this.$message.error('添加失败')
                }
            })
        },
        getStudioInfo() {
            studioInfo({ infoId: this.infoId }).then(res => {
                this.form = res.data.data;
                this.form.fileList.forEach(e => {
                    this.fileList.push({
                        name: e.fileName,
                        fileId: e.fileId,
                        url: e.fileUrl,
                        response: {
                            data: e.fileUrl
                        }
                    })
                })
            })
        },
        checkCoverValidator(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            if (this.form.cover != '') {
                callback()
            } else {
                callback(new Error('请上传封面'))
            }
        },
        checkContentValidator(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            if (this.form.content != '') {
                callback()
            } else {
                callback(new Error('请填写内容'))
            }
        },
        handleAvatarSuccess(response, uploadFile) {
            console.log(response, uploadFile);
            if (uploadFile.response.code == 1) {
                this.form.cover = uploadFile.response.data;
            } else {
                this.$message.error("上传失败")
            }

        },
        beforeAvatarUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type == 'image/jpeg' || rawFile.type == 'image/png' || rawFile.type == 'image/jpg')) {
                this.$message.error('图片必须是jpeg、png、jpg格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 10) {
                this.$message.error('图片大小不能超过10M')
                return false
            }
            return true
        },
        /** 提交按钮 */
        submitForm() {
            console.log(this.form)
            var text = JSON.parse(JSON.stringify(this.form.content))
            text = text.replace(/<video/gi, "<video style='width:100% !important;height:30% !important;background:#333 !important;'")
            text = text.replace(/<img/gi, "<img style='width:auto !important;max-width: 100% !important;height:auto !important;vertical-align: middle !important;max-height:100%;margin:0 auto !important;'")
            text = text.replace(/<p/gi, "<p style='text-indent: unset !important;'")

            console.log(text)
            this.form.appContent = text
            // this.form.content = this.form.appContent
            if (this.form.type == 2 || this.form.type == 4) {
                this.form.cover = ''
            }
            var fileList = [];
            this.fileList.forEach((e) => {
                if (e.response.code == 1) {
                    fileList.push(e.response.data);
                }
            })
            this.$refs["form"].validate(valid => {
                if (valid) {
                    this.form.fileUrls = fileList.toString();
                    if (this.infoId) {
                        this.form.infoId = this.infoId;
                        studioUpdate(this.form).then(res => {
                            if (res.data.code == 1) {
                                this.fileList = []
                                this.delFileList.forEach(e => {
                                    studioDeleteFile({ fileUrl: e });
                                })
                                this.$message.success("编辑成功");
                                if (this.isTeachStudioList == 1) {
                                    this.replace('/teachStudioManage', { isSx: 1 })
                                } else if (this.iswork == 1) {
                                    this.replace('/workBrieManage', { isSx: 1 })
                                } else {
                                    this.replace('/studioManage', { isSx: 1 })
                                }

                            } else {
                                this.$message.error("操作失败")
                            }
                        })
                    } else {
                        studioCreate(this.form).then(res => {
                            if (res.data.code == 1) {
                                this.fileList = []
                                this.delFileList.forEach(e => {
                                    studioDeleteFile({ fileUrl: e });
                                })
                                this.$message.success("新增成功");
                                // this.$store.state.tagsView.visitedViews.forEach(e => {
                                //     if (e.path == '/groupSet') {
                                //         this.$store.dispatch('tagsView/delView', e).then(({
                                //             visitedViews
                                //         }) => {
                                //         })
                                //     }
                                // })
                                if (this.isTeachStudioList == 1) {
                                    this.replace('/teachStudioManage', { isSx: 1 })
                                } else if (this.iswork == 1) {
                                    this.replace('/workBrieManage', { isSx: 1 })
                                } else {
                                    this.replace('/studioManage', { isSx: 1 })
                                }
                                // this.$emit('isTg', true);
                            } else {
                                this.$message.error("操作失败")
                            }

                        });
                    }

                }
            });
        },

        // 取消按钮
        cancel() {
            if (this.isTeachStudioList == 1) {
                this.replace('/teachStudioManage')
            } else if (this.iswork == 1) {
                this.replace('/workBrieManage')
            } else {
                this.replace('/studioManage')
            }
            // this.$emit('isTg', true)
        },
    }
};
</script>
<style scoped>
.avatar-uploader .avatar {
    width: 270px;
    height: 152px;
    display: block;
    object-fit: cover;
    position: absolute;
}

.avatar-uploader /deep/ .el-upload {
    border: 1px dashed var(--el-border-color);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
    border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 270px;
    height: 152px;
    text-align: center;
}

/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

/deep/ .el-dialog__body {
    padding: 10px 20px !important;
}

.custom /deep/ .el-checkbox {
    height: auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.custom /deep/ .el-checkbox-group {
    display: flex;
    flex-wrap: wrap;
}

.custom /deep/ .el-checkbox__label {
    padding-left: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

.custom {
    height: calc(100vh - 500px);
    overflow-y: auto;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    position: relative;
}

.ylsb {
    height: 50vh;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
}

.ylqs {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 7px;
    padding: 5px;
    cursor: pointer;
}

.eicon {
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px;
    background: #dbdbdb;
}

.csbbb:hover .eicon {
    display: block;
}
</style>