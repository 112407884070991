<template>
    <div class="app-container">
        <div class="x-nav">
            <el-breadcrumb :separator="'＞'">
                <el-breadcrumb-item @click="cancel" v-if="isPrincipalStudio == 1"><a>名校长工作室统计</a></el-breadcrumb-item>
                <el-breadcrumb-item @click="cancel" v-if="isMsgzsStatistics == 1"><a>名师工作室统计</a></el-breadcrumb-item>
                <el-breadcrumb-item @click="cancel" v-if="isPtStatistics == 1"><a>一体化教研统计</a></el-breadcrumb-item>
                <el-breadcrumb-item v-if="isMsgzsStatistics != 1 && isPtStatistics != 1 && isPrincipalStudio != 1"
                    @click="cancel"><a>{{ isMyLess == 1 ? '个人备课' : '协同备课' }}</a></el-breadcrumb-item>
                <el-breadcrumb-item>{{ isMyLess == 1 ? '个人备课' : '协同备课' }}详情</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <el-card shadow="never" style="height: calc(100vh - 160px);overflow: auto;">
            <el-descriptions :column="3" class="detailFF" title="基本信息">
                <el-descriptions-item label="学段">{{ form.periodName }}</el-descriptions-item>
                <el-descriptions-item label="年级">{{ form.gradeName }}</el-descriptions-item>
                <el-descriptions-item label="学科">{{ form.subjectName }}</el-descriptions-item>
                <el-descriptions-item label="备课标题" width="33.33%">{{ form.title }}</el-descriptions-item>
                <el-descriptions-item label="备课性质" label-align="center">
                    <span v-if="form.lessonNature == 1">普通</span>
                    <span v-if="form.lessonNature == 2">名师工作室</span>
                    <span v-if="form.lessonNature == 3">名校长工作室</span>
                    <span v-if="form.lessonNature == 4">群组任务</span>
                </el-descriptions-item>
                <el-descriptions-item label="课题级别">{{ form.lessonLevelName }}</el-descriptions-item>
                <el-descriptions-item label="工作室" v-if="form.groupName">{{ form.groupName }}</el-descriptions-item>
                <el-descriptions-item label="备课时段" v-if="form.beginTime">{{ timestampToTime(form.beginTime) }}至{{
                    timestampToTime(form.endTime) }}</el-descriptions-item>
            </el-descriptions>
            <el-form-item class="fs14" label-width="68px" label="主备人" prop="">
                <div>{{ form.leadUserName }}</div>
            </el-form-item>
            <el-form-item v-if="form.teamUserText" class="fs14" label-width="68px" label="协备人员" prop="">
                <expendText :text="form.teamUserText"></expendText>
            </el-form-item>
            <el-form-item v-if="form.guideUserText" class="fs14" label-width="68px" label="专家" prop="">
                <expendText :text="form.guideUserText"></expendText>
            </el-form-item>
            <!-- <el-descriptions :column="1" class="detailFF">
                <el-descriptions-item label="主备人">{{ form.leadUserName }}</el-descriptions-item>
                <el-descriptions-item label="协备人员" v-if="form.teamUserText">
                    <span class="text-three">{{ form.teamUserText }}</span>
                </el-descriptions-item>
            </el-descriptions> -->
            <el-form ref="form" :model="form" :rules="rules" label-width="68px">
                <el-row v-if="isMyLess != 1 && form.jaFiles && form.jaFiles.length != 0" class="mt10">
                    <el-col :span="24">
                        <el-form-item label="初案教案" prop="">
                            <div @click="xz(item)" v-for="item in form.jaFiles" class="csbbb mb10 cursor">
                                <el-icon :size="20" v-has-btn="'1,2'" @click.stop="handleDownload(item)" title="下载"
                                    class="eicon" style="right: 15px;top: 5px;cursor: pointer;z-index: 99;">
                                    <Download />
                                </el-icon>
                                <img v-if="item.status == 1" style="width: 350px;height:300px;object-fit: cover;"
                                    :src="item.imgUrl + '/1.png'" alt="">
                                <div>{{ item.fileName }}</div>
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="isMyLess != 1 && form.xaFiles && form.xaFiles.length != 0">
                    <el-col :span="24">
                        <el-form-item label="初案学案" prop="">
                            <div @click="xz(item)" v-for="item in form.xaFiles" class="csbbb mb10 cursor">
                                <el-icon :size="20" v-has-btn="'1,2'" @click.stop="handleDownload(item)" title="下载"
                                    class="eicon" style="right: 15px;top: 5px;cursor: pointer;z-index: 99;">
                                    <Download />
                                </el-icon>
                                <img v-if="item.status == 1" style="width: 350px;height:300px;object-fit: cover;"
                                    :src="item.imgUrl + '/1.png'" alt="">
                                <div>{{ item.fileName }}</div>
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="isMyLess != 1 && form.kjFiles && form.kjFiles.length != 0">
                    <el-col :span="24">
                        <el-form-item label="初案课件" prop="">
                            <div @click="xz(item)" v-for="item in form.kjFiles" class="csbbb mb10 cursor">
                                <el-icon :size="20" v-has-btn="'1,2'" @click.stop="handleDownload(item)" title="下载"
                                    class="eicon" style="right: 15px;top: 5px;cursor: pointer;z-index: 99;">
                                    <Download />
                                </el-icon>
                                <img v-if="item.status == 1" style="width: 350px;height:300px;object-fit: cover;"
                                    :src="item.imgUrl + '/1.png'" alt="">
                                <div>{{ item.fileName }}</div>
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="isMyLess != 1 && form.xtFiles && form.xtFiles.length != 0">
                    <el-col :span="24">
                        <el-form-item label="初案习题" prop="">
                            <div @click="xz(item)" v-for="item in form.xtFiles" class="csbbb mb10 cursor">
                                <el-icon :size="20" v-has-btn="'1,2'" @click.stop="handleDownload(item)" title="下载"
                                    class="eicon" style="right: 15px;top: 5px;cursor: pointer;z-index: 99;">
                                    <Download />
                                </el-icon>
                                <img v-if="item.status == 1" style="width: 350px;height:300px;object-fit: cover;"
                                    :src="item.imgUrl + '/1.png'" alt="">
                                <div>{{ item.fileName }}</div>
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="isMyLess != 1 && form.ytjaFiles && form.ytjaFiles.length != 0">
                    <el-col :span="24">
                        <el-form-item label-width="85px" label="修正案教案" prop="">
                            <div @click="xz(item)" v-for="item in form.ytjaFiles" class="csbbb mb10 cursor">
                                <el-icon :size="20" v-has-btn="'1,2'" @click.stop="handleDownload(item)" title="下载"
                                    class="eicon" style="right: 15px;top: 5px;cursor: pointer;z-index: 99;">
                                    <Download />
                                </el-icon>
                                <img v-if="item.status == 1" style="width: 350px;height:300px;object-fit: cover;"
                                    :src="item.imgUrl + '/1.png'" alt="">
                                <div>{{ item.fileName }}</div>
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="isMyLess != 1 && form.ytxaFiles && form.ytxaFiles.length != 0">
                    <el-col :span="24">
                        <el-form-item label-width="85px" label="修正案学案" prop="">
                            <div @click="xz(item)" v-for="item in form.ytxaFiles" class="csbbb mb10 cursor">
                                <el-icon :size="20" v-has-btn="'1,2'" @click.stop="handleDownload(item)" title="下载"
                                    class="eicon" style="right: 15px;top: 5px;cursor: pointer;z-index: 99;">
                                    <Download />
                                </el-icon>
                                <img v-if="item.status == 1" style="width: 350px;height:300px;object-fit: cover;"
                                    :src="item.imgUrl + '/1.png'" alt="">
                                <div>{{ item.fileName }}</div>
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="isMyLess != 1 && form.ytkjFiles && form.ytkjFiles.length != 0">
                    <el-col :span="24">
                        <el-form-item label-width="85px" label="修正案课件" prop="">
                            <div @click="xz(item)" v-for="item in form.ytkjFiles" class="csbbb mb10 cursor">
                                <el-icon :size="20" v-has-btn="'1,2'" @click.stop="handleDownload(item)" title="下载"
                                    class="eicon" style="right: 15px;top: 5px;cursor: pointer;z-index: 99;">
                                    <Download />
                                </el-icon>
                                <img v-if="item.status == 1" style="width: 350px;height:300px;object-fit: cover;"
                                    :src="item.imgUrl + '/1.png'" alt="">
                                <div>{{ item.fileName }}</div>
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="isMyLess != 1 && form.ytxtFiles && form.ytxtFiles.length != 0">
                    <el-col :span="24">
                        <el-form-item label-width="85px" label="修正案习题" prop="">
                            <div @click="xz(item)" v-for="item in form.ytxtFiles" class="csbbb mb10 cursor">
                                <el-icon :size="20" v-has-btn="'1,2'" @click.stop="handleDownload(item)" title="下载"
                                    class="eicon" style="right: 15px;top: 5px;cursor: pointer;z-index: 99;">
                                    <Download />
                                </el-icon>
                                <img v-if="item.status == 1" style="width: 350px;height:300px;object-fit: cover;"
                                    :src="item.imgUrl + '/1.png'" alt="">
                                <div>{{ item.fileName }}</div>
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="isMyLess == 1 && form.gajaFiles && form.gajaFiles.length != 0">
                    <el-col :span="24">
                        <el-form-item label-width="40px" label="教案" prop="">
                            <div @click="xz(item)" v-for="item in form.gajaFiles" class="csbbb mb10 cursor">
                                <el-icon :size="20" v-has-btn="'1,2'" @click.stop="handleDownload(item)" title="下载"
                                    class="eicon" style="right: 15px;top: 5px;cursor: pointer;z-index: 99;">
                                    <Download />
                                </el-icon>
                                <img v-if="item.status == 1" style="width: 350px;height:300px;object-fit: cover;"
                                    :src="item.imgUrl + '/1.png'" alt="">
                                <div>{{ item.fileName }}</div>
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="isMyLess == 1 && form.gaxaFiles && form.gaxaFiles.length != 0">
                    <el-col :span="24">
                        <el-form-item label-width="40px" label="学案" prop="">
                            <div @click="xz(item)" v-for="item in form.gaxaFiles" class="csbbb mb10 cursor">
                                <el-icon :size="20" v-has-btn="'1,2'" @click.stop="handleDownload(item)" title="下载"
                                    class="eicon" style="right: 15px;top: 5px;cursor: pointer;z-index: 99;">
                                    <Download />
                                </el-icon>
                                <img v-if="item.status == 1" style="width: 350px;height:300px;object-fit: cover;"
                                    :src="item.imgUrl + '/1.png'" alt="">
                                <div>{{ item.fileName }}</div>
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="isMyLess == 1 && form.gakjFiles && form.gakjFiles.length != 0">
                    <el-col :span="24">
                        <el-form-item label-width="40px" label="课件" prop="">
                            <div @click="xz(item)" v-for="item in form.gakjFiles" class="csbbb mb10 cursor">
                                <el-icon :size="20" v-has-btn="'1,2'" @click.stop="handleDownload(item)" title="下载"
                                    class="eicon" style="right: 15px;top: 5px;cursor: pointer;z-index: 99;">
                                    <Download />
                                </el-icon>
                                <img v-if="item.status == 1" style="width: 350px;height:300px;object-fit: cover;"
                                    :src="item.imgUrl + '/1.png'" alt="">
                                <div>{{ item.fileName }}</div>
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="isMyLess == 1 && form.gaxtFiles && form.gaxtFiles.length != 0">
                    <el-col :span="24">
                        <el-form-item label-width="40px" label="习题" prop="">
                            <div @click="xz(item)" v-for="item in form.gaxtFiles" class="csbbb mb10 cursor">
                                <el-icon :size="20" v-has-btn="'1,2'" @click.stop="handleDownload(item)" title="下载"
                                    class="eicon" style="right: 15px;top: 5px;cursor: pointer;z-index: 99;">
                                    <Download />
                                </el-icon>
                                <img v-if="item.status == 1" style="width: 350px;height:300px;object-fit: cover;"
                                    :src="item.imgUrl + '/1.png'" alt="">
                                <div>{{ item.fileName }}</div>
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
    </div>
</template>

<script>
import { lessonInfo } from "@/api/admin/lesson"
import { timestampToTime } from "@/utils/date";
import { download } from "@/api/adminservice/public"
import { queryDict, userList, listHeadmasterGroup, listTeacherGroup } from "@/api/admin/public";

export default {
    name: "demoAdd",
    components: {
    },
    data() {
        return {
            isZk:false,
            lessonId: '',
            timestampToTime,
            // 表单参数
            form: {
                periodKey: '',
                gradeKey: '',
                subjectKey: '',
                lessonLevelKey: '',
                title: '',
                leadUser: '',
                teamUsers: '',
                teamuseList: [],
                leaderList: []
            },
            // 表单校验
            rules: {
            },
            isMyLess: 0,
            isPtStatistics: 0,
            isMsgzsStatistics: 0,
            isPrincipalStudio: 0,
            groupList: [],
        };
    },
    created() {
    },
    mounted() {
        this.lessonId = this.$route.query.lessonId
        this.getlessonInfo()
        if (this.$route.query.isMyLess) {
            this.isMyLess = this.$route.query.isMyLess
        }
        if (this.$route.query.isPtStatistics) {
            this.isPtStatistics = this.$route.query.isPtStatistics
        }
        if (this.$route.query.isMsgzsStatistics) {
            this.isMsgzsStatistics = this.$route.query.isMsgzsStatistics
        }
        if (this.$route.query.isPrincipalStudio) {
            this.isPrincipalStudio = this.$route.query.isPrincipalStudio
        }
    },
    computed: {
    },
    methods: {
        handleDownload(item) {
            console.log(item);
            download(item.fileUrl, item.fileName)
        },
        changeLessonNature(e) {
            console.log(e);
            this.groupList = [];
            var userId = JSON.parse(this.$storage.get("userInfo")).userId
            if (e == 2) {
                listTeacherGroup().then(res => {
                    res.data.data.forEach(j => {
                        if (this.form.groupId == j.groupId) {
                            this.form.groupName = j.groupName
                        }
                        j.memberList.forEach(m => {
                            if (m.memberRole == 0 || m.memberRole == 1) {
                                if (m.memberUserId == userId) {
                                    this.groupList.push(j)
                                    // throw new Error();
                                }
                            }
                        })
                    })
                })
            } else if (e == 3) {
                listHeadmasterGroup().then(res => {
                    res.data.data.forEach(j => {
                        if (this.form.groupId == j.groupId) {
                            this.form.groupName = j.groupName
                        }
                        j.memberList.forEach(m => {
                            if (m.memberRole == 0 || m.memberRole == 1) {
                                if (m.memberUserId == userId) {
                                    this.groupList.push(j)
                                    // throw new Error();
                                }
                            }
                        })
                    })
                })
            }
        },
        xz(item) {
            // let routeUrl = this.$router.resolve({
            //     path: '/lessonFileDetail',// 这里的路径就可以正常的写，不需要添加_blank: true
            //     query: { data: JSON.stringify(item) }
            // })
            // window.open(routeUrl.href, '_blank')
            let routeUrl = this.$router.resolve({
                path: '/lessonDiscuss',// 这里的路径就可以正常的写，不需要添加_blank: true
                query: { lessonId: this.lessonId, isRd: 1 }
            })
            window.open(routeUrl.href, '_blank')
        },
        getlessonInfo() {
            lessonInfo({ lessonId: this.lessonId }).then(res => {
                this.changeLessonNature(res.data.data.lessonNature)
                var textList = [];
                res.data.data.teamUserList.forEach(j => {
                    textList.push(j.trueName)
                })
                res.data.data.teamUserText = textList.join('、')
                var zjtextList = [];
                res.data.data.guideUserList.forEach(j => {
                    zjtextList.push(j.trueName)
                })
                res.data.data.guideUserText = zjtextList.join('、')
                this.form = res.data.data;
            })
        },
        // 取消按钮
        cancel() {
            if (this.isPtStatistics == 1) {
                this.replace('/ptStatistics')
            } else if (this.isMsgzsStatistics == 1) {
                this.replace('/msgzsStatistics')
            } else if (this.isPrincipalStudio == 1) {
                this.replace('/principalStudio')
            } else {
                if (this.isMyLess == 1) {
                    this.replace('/myLess')
                } else {
                    this.replace('/lesson')
                }
            }

            // this.$emit('isTg', true)
        },
    }
};
</script>
<style scoped>
.avatar-uploader .avatar {
    width: 178px;
    height: 178px;
    display: block;
    object-fit: cover;
}

.avatar-uploader /deep/ .el-upload {
    border: 1px dashed var(--el-border-color);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
    border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    text-align: center;
}

/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

/deep/ .el-dialog__body {
    padding: 10px 20px !important;
}

.custom /deep/ .el-checkbox {
    height: auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.custom /deep/ .el-checkbox-group {
    display: flex;
    flex-wrap: wrap;
}

.custom /deep/ .el-checkbox__label {
    padding-left: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

.custom {
    height: calc(100vh - 500px);
    overflow-y: auto;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    position: relative;
}

.ylsb {
    height: 50vh;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
}

.ylqs {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 7px;
    padding: 5px;
    cursor: pointer;
}

.eicon {
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px;
    background: #dbdbdb;
}

.csbbb:hover .eicon {
    display: block;
}
</style>