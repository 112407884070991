<template>
    <div class="app-container">
        <div class="x-nav">
            <el-breadcrumb :separator="'＞'">
                <el-breadcrumb-item @click="cancel"><a>成果展示</a></el-breadcrumb-item>
                <el-breadcrumb-item v-if="!achieveId">新增成果展示</el-breadcrumb-item>
                <el-breadcrumb-item v-if="achieveId">编辑成果展示</el-breadcrumb-item>

            </el-breadcrumb>
        </div>

        <el-card shadow="never" style="height: calc(100vh - 160px);overflow: auto;">
            <el-form ref="form" :model="form" :rules="rules" label-width="110px">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="成果标题" prop="achieveTitle">
                            <el-input v-model="form.achieveTitle" placeholder="最多32个字" maxlength="32" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="学段" prop="periodKey">
                            <el-select @change="changePeriod" v-model="form.periodKey" placeholder="请选择学段">
                                <el-option v-for="item in periodList" :key="item.dictKey" :label="item.dictValue"
                                    :value="item.dictKey" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="年级" prop="gradeKey">
                            <el-select @change="changeGrade" v-model="form.gradeKey" placeholder="请选择年级">
                                <el-option v-for="item in gradeList" :key="item.dictKey" :label="item.dictValue"
                                    :value="item.dictKey" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="学科" prop="subjectKey">
                            <el-select v-model="form.subjectKey" placeholder="请选择学科">
                                <el-option v-for="item in subjectList" :key="item.dictKey" :label="item.dictValue"
                                    :value="item.dictKey" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="发表人" prop="userId">
                            <!-- <el-select @change="changeUserId" style="width: 100%;" filterable v-model="form.userId"
                                placeholder="请选择发表人">
                                <el-option v-for="item in userList" :key="item.userId" :label="item.trueName"
                                    :value="item.userId" />
                            </el-select> -->
                            <choosePeople :showTitle="'选择人员'" :radio="true" ref="userId" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <!-- <el-row>
                    <el-col :span="12">
                        <el-form-item label="归属" prop="orgName">
                            {{ form.orgName }}
                        </el-form-item>
                    </el-col>
                </el-row> -->
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="成果简介" prop="achieveInfo">
                            <el-input type="textarea" maxlength="1000" v-model="form.achieveInfo" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="类别" prop="achieveType">
                            <el-radio-group v-model="form.achieveType">
                                <el-radio :label="item.dictKey" v-for="item in achieveTypeList">{{ item.dictValue
                                }}</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="成果封面" prop="coverUrl">
                            <el-upload class="avatar-uploader"
                                action="https://www.liyunjy.com/businessservice/achieve/uploadCover" :show-file-list="false"
                                :on-success="handleAvatarSuccess" :headers="{ token }" :before-upload="beforeAvatarUpload">
                                <img v-if="form.coverUrl" :src="form.coverUrl" class="avatar" style="position:absolute" />
                                <el-icon class="avatar-uploader-icon" style="font-size:100px;color:#fff;">
                                    <Plus />
                                </el-icon>
                            </el-upload>
                            <div style="color: #a9abad;width:100%;">推荐使用540*304尺寸的图片</div>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row v-if="form.achieveType == 1 || form.achieveType == 3">
                    <el-col :span="12">
                        <el-form-item label="上传附件" prop="fileUrl">
                            <el-upload v-model:file-list="fileList" class="upload-demo" :on-remove="handleRemove"
                                action="https://www.liyunjy.com/businessservice/achieve/uploadFile" :limit="1"
                                :on-exceed="handleExceed" :on-change="handleJAChange" :headers="{ token }"
                                accept=".pdf,.ppt,.pptx,.doc,.docx" :before-upload="beforeJAUpload">
                                <el-button type="primary">点击上传</el-button>
                                <template #tip>
                                    <div class="el-upload__tip">
                                        只允许上传.pdf,.ppt,.pptx,.doc,.docx格式文件。
                                    </div>
                                </template>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="form.achieveType == 2">
                    <el-col :span="12">
                        <el-form-item label="上传附件" prop="fileUrl">
                            <el-upload v-model:file-list="fileList" class="upload-demo" :on-remove="handleRemove"
                                action="https://www.liyunjy.com/businessservice/achieve/uploadFile" :limit="1"
                                :on-exceed="handleExceed" :on-change="handleJAChange" :headers="{ token }"
                                accept=".MP4,.mp4,.avi,.AVI">
                                <el-button type="primary">点击上传</el-button>
                                <template #tip>
                                    <div class="el-upload__tip">
                                        只允许上传.MP4,.mp4,.avi,.AVI格式文件。
                                    </div>
                                </template>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>

        <div>
            <div class="disflex-row-center cusbtbtn" :style="$store.state.zdy.isActive ? 'left:26px;' : 'left:226px;'">
                <el-button @click="cancel">取 消</el-button>
                <el-button type="primary" @click="submitForm">保 存</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import { achieveCreate, achieveInfo, achieveUpdate, achieveDelFile } from "@/api/admin/achieve"
import { trainListReleaseCourse } from "@/api/admin/traCourse"
import { queryDict, userList } from "@/api/admin/public";
import { orgListTypeOrg, orgListSchoolOrg } from "@/api/admin/system";

export default {
    name: "demoAdd",
    components: {
    },
    data() {
        return {
            achieveTypeList: [],
            fileList: [],
            toParentsMap: {

            },
            isShow: false,
            oldCenter: [],
            achieveId: '',
            periodList: [],
            gradeList: [],
            subjectList: [],
            lessonLevelList: [
            ],
            userList: [],
            token: this.$storage.get("token"),
            // 遮罩层
            loading: true,
            // 非单个禁用
            single: true,
            fileList: [],
            // 表单参数
            form: {
                coverUrl: 'https://free-back.oss-cn-beijing.aliyuncs.com/ygjy/default/msyk.png',
                achieveTitle: '',
                periodKey: '',
                gradeKey: '',
                subjectKey: '',
                userId: '',
                orgName: '',
                achieveInfo: '',
                achieveType: '1',
            },
            // 表单校验
            rules: {
                coverUrl: [
                    { required: true, validator: this.checkCoverValidator }
                ],
                fileUrl: [
                    { required: true, validator: this.checkfileUrlValidator }
                ],
                achieveType: [
                    { required: true, message: "请选择类别", trigger: "change" },
                ],
                achieveTitle: [
                    { required: true, message: "请输入成果标题", trigger: "blur" },
                ],
                periodKey: [
                    { required: true, message: "请选择学段", trigger: "change" },
                ],
                gradeKey: [
                    { required: true, message: "请选择年级", trigger: "change" },
                ],
                subjectKey: [
                    { required: true, message: "请选择学科", trigger: "change" },
                ],
                userId: [
                    { required: true, message: "请选择发表人", trigger: "change" },
                ],
                achieveInfo: [
                    { required: true, message: "请输入成果简介", trigger: "blur" },
                ],
                achieveType: [
                    { required: true, message: "请选择类别", trigger: "change" },
                ],
            },
            delFileList: [],
            countyList: [],
            schoolList: [],
            trainList: [],
        };
    },
    created() {
        this.getTrainListCourse();
        this.getOrgListTypeOrg();
        this.getQueryDict();
    },
    mounted() {
        if (this.$route.query.achieveId) {
            this.achieveId = this.$route.query.achieveId
            console.log(this.form)
            this.getlessonInfo()
        } else {
            this.isShow = true
        }
    },
    unmounted() {
        if (this.achieveId) {
            try {
                console.log(332)
                this.fileList.forEach(e => {
                    console.log(e)
                    if (e.response.code) {
                        achieveDelFile({ fileUrl: e.response.data });
                    }
                })
            } catch (e) {
                console.log('e', e)
            }
        } else {
            try {
                this.fileList.forEach(e => {
                    achieveDelFile({ fileUrl: e.response.data });
                })
            } catch (e) {
                console.log('e', e)
            }

        }
    },
    computed: {
    },
    methods: {
        handleExceed(e) {
            console.log(e, this.fileList);
            if (this.fileList.length == 1) {
                this.$message.error('只能上传一个附件')
            }
        },
        changeUserId(e) {
            this.userList.forEach(j => {
                if (e == j.userId) {
                    this.form.orgName = j.orgName
                }
            })
        },
        getTrainListCourse() {
            trainListReleaseCourse().then(res => {
                this.trainList = res.data.data;
                console.log(this.trainList)
            })
        },
        changeSignInTime(e) {
            console.log(this.form.signInTime);
            this.form.signInBeginTime = this.form.signInTime[0];
            this.form.signInEndTime = this.form.signInTime[1];
        },
        changeSignOutTime(e) {
            console.log(this.form.signOutTime);
            this.form.signOutBeginTime = this.form.signOutTime[0];
            this.form.signOutEndTime = this.form.signOutTime[1];
        },
        checkCoverValidator(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            if (this.form.coverUrl != '') {
                callback()
            } else {
                callback(new Error('请上传封面'))
            }
        },
        checkfileUrlValidator(rule, value, callback) {
            if (this.fileList.length != 0) {
                var filetype = this.fileList[0].name.slice(-4);
                if (this.form.achieveType == 2) {
                    if (filetype != '.MP4' && filetype != '.mp4' && filetype != '.avi' && filetype != '.AVI') {
                        callback(new Error('只允许上传.MP4,.mp4,.avi,.AVI格式文件。'))
                    } else {
                        callback()
                    }
                } else {
                    if (filetype != '.pdf' && filetype != '.ppt' && filetype != 'pptx') {
                        callback(new Error('只允许上传.pdf,.ppt,.pptx格式文件。'))
                    } else {
                        callback()
                    }
                }

            } else {
                callback(new Error('请上传附件'))
            }
        },
        handleRemove(file, uploadFiles) {
            console.log(file, uploadFiles)
            if (file.response.data) {
                this.delFileList.push(file.response.data)
            }
        },
        getlessonInfo() {
            queryDict({ code: 'lession_xd_nj_xk' }).then(res => {
                this.periodList = res.data.data;
                achieveInfo({ achieveId: this.achieveId }).then(res => {
                    this.form = res.data.data;
                    this.$refs.userId.checkList = [{ userId:this.form.userId,trueName:this.form.userName }];
                    this.form.orgName = res.data.data.userOrgName;
                    this.form.fileList.forEach(e => {
                        this.fileList.push({
                            name: e.fileName,
                            fileId: e.fileId,
                            url: e.fileUrl,
                            response: {
                                data: e.fileUrl
                            }
                        })
                    })
                    this.periodList.forEach(j => {
                        if (this.form.periodKey == j.dictKey) {
                            this.gradeList = j.childrenList;
                        }
                    })
                    this.periodList.forEach(e => {
                        if (this.form.periodKey == e.dictKey) {
                            e.childrenList.forEach(j => {
                                if (this.form.gradeKey == j.dictKey) {
                                    this.subjectList = j.childrenList;
                                }
                            })
                        }
                    })
                })
            })
        },
        handleXTChange(file) {
            console.log(file)
        },
        handleJAChange(file) {
            console.log(file)

        },
        handleKjChange(file) {
            console.log(file)

        },
        beforeJAUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type == 'application/pdf' || rawFile.type == 'application/vnd.ms-powerpoint' || rawFile.type == 'application/vnd.openxmlformats-officedocument.presentationml.presentation' || rawFile.type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' || rawFile.type == 'application/msword')) {
                this.$message.error('文件必须是.pdf,.ppt,.pptx,.doc,.docx格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 200) {
                this.$message.error('文件大小不能超过200M')
                return false
            }
            return true
        },
        startDateConfirm(e) {
            if (e > this.form.endTime) {
                this.form.endTime = '';
            }
        },
        startSignInDateConfirm(e) {
            if (e > this.form.signInEndTime) {
                this.form.signInEndTime = '';
            }
        },
        startSignOutDateConfirm(e) {
            if (e > this.form.signOutEndTime) {
                this.form.signOutEndTime = '';
            }
        },
        endDateConfirm(e) {
            console.log(e,)
            if (e < this.form.beginTime) {
                this.form.beginTime = '';
            }
        },
        endSignInDateConfirm(e) {
            console.log(e,)
            if (e < this.form.signInBeginTime) {
                this.form.signInBeginTime = '';
            }
        },
        endSignOutDateConfirm(e) {
            console.log(e,)
            if (e < this.form.signOutBeginTime) {
                this.form.signOutBeginTime = '';
            }
        },
        checkValidator(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            if (this.form.beginTime === '' || !this.form.beginTime) {
                callback(new Error('请选择开始日期'))
            } else if (this.form.endTime === '' || !this.form.endTime) {
                callback(new Error('请选择结束日期'))
            } else {
                callback()
            }
        },
        disabledDate(current) {
            return current && current < Number(new Date()) - 86400000
        },
        enddisabledDate(current) {
            if (this.form.beginTime) {
                return current && current < this.form.beginTime
            } else {
                return current && current < Number(new Date()) - 86400000
            }
        },
        changeCounty() {
            this.form.schoolId = '';
            this.schoolList = [];
            orgListSchoolOrg({ orgId: this.form.countyId }).then(res => {
                this.schoolList = res.data.data;
            })
        },
        changePeriod() {
            this.form.gradeKey = '';
            this.form.subjectKey = '';
            this.gradeList = [];
            this.subjectList = [];
            this.periodList.forEach(j => {
                if (this.form.periodKey == j.dictKey) {
                    this.gradeList = j.childrenList;
                }
            })
        },
        changeGrade() {
            this.form.subjectKey = '';
            this.subjectList = []
            this.periodList.forEach(e => {
                if (this.form.periodKey == e.dictKey) {
                    e.childrenList.forEach(j => {
                        if (this.form.gradeKey == j.dictKey) {
                            this.subjectList = j.childrenList;
                        }
                    })
                }
            })
        },
        getOrgListTypeOrg() {
            orgListTypeOrg({ orgType: 2 }).then(res => {
                this.countyList = res.data.data;
            })
        },
        getQueryDict() {
            queryDict({ code: 'lession_xd_nj_xk' }).then(res => {
                this.periodList = res.data.data;
            })
            queryDict({ code: 'topic_level' }).then(res => {
                this.lessonLevelList = res.data.data;
            })
            queryDict({ code: 'achieve_type' }).then(res => {
                this.achieveTypeList = res.data.data;
            })
            userList().then(res => {
                this.userList = res.data.data;
            })
        },
        handleAvatarSuccess(response, uploadFile) {
            console.log(response, uploadFile);
            if (uploadFile.response.code == 1) {
                this.form.coverUrl = uploadFile.response.data;
            } else {
                this.$message.error("上传失败")
            }

        },
        beforeAvatarUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type == 'image/jpeg' || rawFile.type == 'image/png' || rawFile.type == 'image/jpg')) {
                this.$message.error('图片必须是jpeg、png、jpg格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 10) {
                this.$message.error('图片大小不能超过10M')
                return false
            }
            return true
        },
        /** 提交按钮 */
        submitForm() {
            console.log(this.form)
            console.log(this.fileList);
            var fileList = [];
            if(this.$refs.userId.checkList.length != 0){
                this.form.userId = this.$refs.userId.checkList[0].userId
            } else {
                this.form.userId = ''
            }
            this.fileList.forEach((e) => {
                if (e.response.code == 1 || e.response.data) {
                    fileList.push(e.response.data);
                }
            })
            this.$refs["form"].validate(valid => {
                if (valid) {
                    var obj = JSON.parse(JSON.stringify(this.form));
                    obj.fileUrl = fileList.toString();

                    if (this.achieveId) {
                        achieveUpdate(obj).then(res => {
                            if (res.data.code == 1) {
                                this.fileList = []
                                this.delFileList.forEach(e => {
                                    achieveDelFile({ fileUrl: e });
                                })
                                this.$message.success("新增成功");
                                this.replace('/achieve', { isSx: 1 })
                                // this.$emit('isTg', true);
                            } else {
                                this.$message.error("操作失败")
                            }

                        });

                    } else {
                        achieveCreate(obj).then(res => {
                            if (res.data.code == 1) {
                                this.fileList = []
                                this.delFileList.forEach(e => {
                                    achieveDelFile({ fileUrl: e });
                                })
                                this.$message.success("编辑成功");
                                this.replace('/achieve', { isSx: 1 })
                            } else {
                                this.$message.error("操作失败")
                            }
                        })
                    }

                }
            });
        },

        // 取消按钮
        cancel() {
            // this.jAFileList = [];
            this.replace('/achieve')
            // this.$emit('isTg', true)
        },
    }
};
</script>
<style scoped>
.avatar-uploader .avatar {
    width: 270px;
    height: 152px;
    display: block;
    object-fit: cover;
}

.avatar-uploader /deep/ .el-upload {
    border: 1px dashed var(--el-border-color);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
    border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 270px;
    height: 152px;
    text-align: center;
}

/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

/deep/ .el-dialog__body {
    padding: 10px 20px !important;
}

.custom /deep/ .el-checkbox {
    height: auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.custom /deep/ .el-checkbox-group {
    display: flex;
    flex-wrap: wrap;
}

.custom /deep/ .el-checkbox__label {
    padding-left: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

.custom {
    height: calc(100vh - 500px);
    overflow-y: auto;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    position: relative;
}

.ylsb {
    height: 50vh;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
}

.ylqs {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 7px;
    padding: 5px;
    cursor: pointer;
}

.eicon {
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px;
    background: #dbdbdb;
}

.csbbb:hover .eicon {
    display: block;
}
</style>